<template>
  <section class="bg_color">
    <h2>過去のオーナーランキング</h2>
    <section class="inner">
      <!--owner-->
      <div v-if="info">
        <div class="box_lst rank last_year">
          <h4 class="ttl_lst">
            <p class="inner crown">
              2023/24season<span class="invisible_sp">&nbsp;</span
              ><br class="hidden-md" />オーナーランキング
            </p>
          </h4>
          <ul class="lst">
            <li class="first">
              <p class="ribon_rank first">1<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico03.svg" alt="" />
                </figure>
                <p class="name">末吉大吉</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">96,393,000</p>
              </div>
            </li>
            <li class="second">
              <p class="ribon_rank first">2<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico01.svg" alt="" />
                </figure>
                <p class="name">山</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">94,734,000</p>
              </div>
            </li>
            <li class="third">
              <p class="ribon_rank first">3<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico05.svg" alt="" />
                </figure>
                <p class="name">すけのすけ</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">94,364,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">4<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico05.svg" alt="" />
                </figure>
                <p class="name">社会人</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">94,127,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">5<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico15.svg" alt="" />
                </figure>
                <p class="name">しん</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">94,060,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">6<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico06.svg" alt="" />
                </figure>
                <p class="name">なっつみー</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">94,029,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">7<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico01.svg" alt="" />
                </figure>
                <p class="name">ハチ</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">94,000,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">8<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico04.svg" alt="" />
                </figure>
                <p class="name">ハル456</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">93,906,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">9<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico01.svg" alt="" />
                </figure>
                <p class="name">ルシファー</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">93,653,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">10<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico01.svg" alt="" />
                </figure>
                <p class="name">59ファイター</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">93,583,000</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div class="box_lst rank last_year">
          <h4 class="ttl_lst">
            <p class="inner crown">
              2022/23season<span class="invisible_sp">&nbsp;</span
              ><br class="hidden-md" />オーナーランキング
            </p>
          </h4>
          <ul class="lst">
            <li class="first">
              <p class="ribon_rank first">1<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico01.svg" alt="" />
                </figure>
                <p class="name">まっし</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">77,660,000</p>
              </div>
            </li>
            <li class="second">
              <p class="ribon_rank first">2<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico18.svg" alt="" />
                </figure>
                <p class="name">パパリンコ</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">77,456,000</p>
              </div>
            </li>
            <li class="third">
              <p class="ribon_rank first">3<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico02.svg" alt="" />
                </figure>
                <p class="name">けんた</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">76,717,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">4<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico01.svg" alt="" />
                </figure>
                <p class="name">かとりょう</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">75,744,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">5<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico05.svg" alt="" />
                </figure>
                <p class="name">山崎智也の影</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">75,682,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">6<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico01.svg" alt="" />
                </figure>
                <p class="name">なじわら</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">74,126,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">7<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico02.svg" alt="" />
                </figure>
                <p class="name">ベック</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">74,101,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">8<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico03.svg" alt="" />
                </figure>
                <p class="name">かばっち</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">73,198,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">9<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico03.svg" alt="" />
                </figure>
                <p class="name">ふかひで</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">73,197,000</p>
              </div>
            </li>
            <li>
              <p class="ribon_rank first">10<span class="small">位</span></p>
              <div class="box_thum">
                <figure>
                  <img src="@/assets/images/parts/ico14.svg" alt="" />
                </figure>
                <p class="name">アゴマスク</p>
              </div>
              <div class="box_dtl">
                <p class="ttl">総獲得モグー</p>
                <p class="txt point">73,113,000</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Mixin from "@/mixins/mixin";
import { mapGetters } from "vuex";

export default {
  name: "RankingLastYear",
  mixins: [Mixin],
  beforeMount: function () {
    this.$store.dispatch("rankingOwnerInfo");
  },
  mounted: function () {
    this.$setInterval(() => {
      this.$store.dispatch("rankingOwnerInfo");
    }, 15000);
  },
  computed: {
    ...mapGetters({
      info: "getRankingOwnerInfo",
      Icon: "getIcon",
    }),
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking_last_year.css" scoped></style>
